a { cursor: pointer }

body {
  overflow-x: hidden;
  background: url(assets/components/images/bg.jpg) no-repeat center center fixed;
  background-size: cover;
}
  
  #sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
    box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
  }

    #sidebar-wrapper .sidebar-heading {
      padding: 0.875rem 1.25rem;
      font-size: 1.2rem;
      
    }
  
  #sidebar-wrapper .list-group {
    width: 15rem;
  }
  
  #page-content-wrapper {
    min-width: 100vw;
  }
  
  #wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
  }
  
  @media (min-width: 768px) {
    #sidebar-wrapper {
      margin-left: 0;
    }
  
    #page-content-wrapper {
      min-width: 0;
      width: 100%;
    }
    .show
    {
      margin-left: 0!important;
    }
    .hide
    {
      margin-left: -15rem!important;
    }
  }
  @media (max-width: 767px) {
    .show
    {
      margin-left: -15rem!important;
    }
    .hide
    {
      margin-left: -0!important;
    }
  }


  .p-toolbar{
    border: 0;
  }

  
  .p-datatable .p-datatable-header {
      padding:0;
    }

  .logo{
    color: #0d6efd;
    font-weight:bolder;
  }